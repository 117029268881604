body {
  padding-top: 0px;
}

.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

label span{
  display:inline-block;
  margin-left: 10px;
}

input[type="radio"]{
  margin: 0 10px 0 10px;
}

a.footnote {
   text-decoration:none;
   /* background-color: #FEF6BB; */
   padding-left: 2px;
   padding-right: 2px;
   margin-right: 2px;
   -webkit-transition: all 2s ease;
   -moz-transition: all 2s ease;
   -o-transition: all 2s ease;
   transition: all 2s ease;
} 

a.footnote span {
   z-index: -1;
   opacity: 0;
   position: fixed;
   left: 15px; 
   bottom: 20px;
   margin-left: 0px;
   margin-right: 18px;
   padding:14px 20px;
   border-radius:4px; box-shadow: 5px 5px 8px #CCC;
   border:1px solid #DCA;
   background-color: #FEF6BB;
   -webkit-transition: all 2s ease;
   -moz-transition: all 2s ease;
   -o-transition: all 2s ease;
   transition: all 2s ease;
}

a.footnote:hover span {
   z-index: 9;
   opacity: 1;
   -webkit-transition: all 2s ease;
   -moz-transition: all 2s ease;
   -o-transition: all 2s ease;
   transition: all 2s ease;
}